import React from 'react';
import { useState, useRef } from 'react';

import RecaptchaUtils from '../utils/RecaptchaUtils';

import Loader from './Loader'
import FormLabel from './FormLabel'
import './Formulario.css';

function Formulario(props) {
  const { onSuccess } = props;
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [Intermediario, setIntermediario] = useState('Sim');
  const [isDisabled, setIsDisabled] = useState(false);
  const [serverError, setServerError] = useState('');

  const nomeRef = useRef(null);
  const telefoneRef = useRef(null);
  const territorioRef = useRef(null);
  const presencialOnlineRef = useRef(null);
  const areaDeAtendimentoRef = useRef(null);

  const opcoes = [
    {
      area: 'Educação',
      texto: 'Educação'
    },
    {
      area: 'Psicologia',
      texto: 'Psicologia'
    },
    {
      area: 'Serviço Social',
      texto: 'Identidade'
    },
    {
      area: 'Serviço Social',
      texto: 'Dúvidas sobre auxílios do governo'
    },
    {
      area: 'Direito',
      texto: 'Aposentadoria/INSS'
    },
    {
      area: 'Direito',
      texto: 'Outras dúvidas com advogado'
    },
    {
      area: 'Serviço Social',
      texto: 'Outras dúvidas com assistente social'
    },
    {
      area: 'Serviço Social',
      texto: 'Certificado de reservista'
    },
    {
      area: 'Serviço Social',
      texto: 'CPF'
    },
    {
      area: 'Serviço Social',
      texto: 'Carteira de Trabalho'
    },
    {
      area: 'Serviço Social',
      texto: 'Habilitação'
    }
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const ValidaFormulario = (data) => {
    const errors = {};
    setFormErrors({});
    let inputToFocus = null;

    if (!data.areaDeAtendimento) {
      errors.areaDeAtendimento = true;
      inputToFocus = areaDeAtendimentoRef;
    }
    if (!data.presencialOnline) {
      errors.presencialOnline = true;
      inputToFocus = presencialOnlineRef;
    }
    if (!data.territorio) {
      errors.territorio = true;
      inputToFocus = territorioRef;
    }
    if (!data.telefone) {
      errors.telefone = true;
      inputToFocus = telefoneRef;
    }
    if (!data.nome) {
      errors.nome = true;
      inputToFocus = nomeRef;
    }
    if (inputToFocus) {
      inputToFocus.current.focus();
    }
    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsDisabled(true);
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    if (!ValidaFormulario(data)) {
      setIsDisabled(false);
      return;
    }
    const opcaoIndice = parseInt(data.areaDeAtendimento, 10);
    data.areaDeAtendimento = opcoes[opcaoIndice].area;
    data.duvidaDescricao = `${opcoes[opcaoIndice].texto}\n\n${data.duvidaDescricao}`;
    try {
      data['g-recaptcha-response'] = await RecaptchaUtils.getRecaptchaToken('cadastraDemanda')
    } catch(e) {
      setServerError('Ocorreu algum erro ao enviar o formulário.\nPor favor, tente mais tarde!');
      setIsDisabled(false)
      return;
    }
    fetch(`${process.env.REACT_APP_API_URL}/requisicao`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      if (response.ok) {
        onSuccess();
      } else if (response.status === 400) {
        response.json().then((data) => {
          let errorMessage = 'Ocorreram erros durante o envio do formulário: \n';
          const keys = Object.keys(data);
          keys.forEach(key => {
            const err = data[key]
            if (err.name === 'ValidatorError') {
              errorMessage += `- ${err.message}\n`
            }
          })
          setServerError(errorMessage);
          setIsDisabled(false);
        })
      } else if (response.status >= 500) {
        setServerError('Ocorreu algum erro ao enviar o formulário.\nPor favor, tente mais tarde!');
        setIsDisabled(false);
      }
    })
    .catch(() => {
      setServerError('Ocorreu algum erro ao enviar o formulário.\nPor favor, tente mais tarde!');
      setIsDisabled(false);
    });
  };

  return (
    <form className="Formulario" onSubmit={handleSubmit}>
      <h2 className="title">Formulário de atendimento</h2>
      <p className='warning'>os campos com "*" são obrigatorios</p> 
      <div className="sessao">
        <FormLabel title="Nome*" text='Nome' />
        <input
          className="campo"
          type="text"
          name="nome"
          ref={nomeRef}
          placeholder="Seu nome"
          onChange={handleInputChange}
          value={formValues.nome || ''}
        />
        {formErrors.nome && <p className="error">*O nome é obrigatório</p>}
      </div>
      <div className="sessao">
        <div className="separador">
          <FormLabel title="Telefone*" text='Telefone' />
          <input
            className="campo"
            type="text"
            name="telefone"
            ref={telefoneRef}
            placeholder="(21) 00000-0000"
            onChange={handleInputChange}
            value={formValues.telefone || ''}
          />
          {formErrors.telefone && (
            <p className="error">*O telefone é o meio de contato obrigatório</p>
          )}
        </div>
      </div>

      <div className="sessao">
        <div className="espacamento">
          <FormLabel title="Será você quem atenderá o telefone?" />
          <div>
            <input
              type="radio"
              id="sim"
              name="Seu"
              value="Sim"
              onChange={() => setIntermediario('Sim')}
              checked={Intermediario === 'Sim'}
            />
            <label className="labelpadrao" htmlFor="sim">
              Sim, serei eu
            </label>
          </div>
          <div>
            <input
              type="radio"
              id="nao"
              name="Seu"
              value="Não"
              onChange={() => setIntermediario('Não')}
              checked={Intermediario === 'Não'}
            />
            <label className="labelpadrao" htmlFor="nao">
              Não, será um intermediário
            </label>
          </div>
        </div>

        {Intermediario === 'Não' && (
          <div className="intermediario">
            <FormLabel title="Nome de quem atenderá o telefone"/>
            <input
              className="campo"
              type="text"
              name="nomeDoIntermediario"
              placeholder="Nome do intermediário"
              onChange={handleInputChange}
              value={formValues.nomeDoIntermediario || ''}
            />
          </div>
        )}
      </div>

      <div className="sessao">
        <FormLabel title="Em que bairro/comunidade você mora?*" text='Em que bairro/comunidade você mora?' />
        <input
          className="campo"
          type="text"
          name="territorio"
          ref={territorioRef}
          placeholder="ex: Pavuna"
          onChange={handleInputChange}
          value={formValues.territorio || ''}
        />
        {formErrors.territorio && (
          <p className="error">*É necessário informar sua região de moradia</p>
        )}
      </div>
      <div className="sessao">
        <div className="espacamento">
          <FormLabel title="O atendimento será presencial ou online?*" text='O atendimento será presencial ou online?' />

          <div className="radio-group">
            <div className="radio-item">
              <input
                type="radio"
                id="presencial"
                ref={presencialOnlineRef}
                name="presencialOnline"
                value="Presencial"
                onChange={handleInputChange}
                checked={formValues.presencialOnline === 'Presencial'}
              />
              <label className="labelpadrao" htmlFor="presencial">
                Presencial
              </label>
            </div>

            <div className="radio-item">
              <input
                type="radio"
                id="online"
                name="presencialOnline"
                value="Online"
                onChange={handleInputChange}
                checked={formValues.presencialOnline === 'Online'}
              />
              <label className="labelpadrao" htmlFor="online">
                Online
              </label>
            </div>
          </div>
          {formErrors.presencialOnline && (
            <p className="error">*É necessário informar se será presencial ou online</p>
          )}
        </div>
      </div>

      <div className="sessao">
        <FormLabel title="Que tipo de atendimento você quer?*" text='Que tipo de atendimento você quer?' />
        <select
          className="campo select"
          name="areaDeAtendimento"
          ref={areaDeAtendimentoRef}
          onChange={handleInputChange}
          value={formValues.areaDeAtendimento || ''}
        >
          <option value="">Selecione</option>
          {opcoes.map((item, index) => {
            return (
              <option key={`${index}-${item.area}`} value={index}>
                {item.texto}
              </option>
            );
          })}
        </select>
        {formErrors.areaDeAtendimento && (
          <p className="error">*Você precisa selecionar o campo em que precisa de ajuda!</p>
        )}
      </div>

      <div className="sessao">
        <FormLabel title="Se quiser, nos fale mais sobre o problema que você quer resolver (opcional):" />
        <textarea
          className="duvida-descricao campo"
          name="duvidaDescricao"
          value={formValues.duvidaDescricao || ''}
          onChange={handleInputChange}
        >
        </textarea>
      </div>
      
      <div>
        <button className="button button-enviar" type="submit" disabled={isDisabled}>
          {isDisabled === true ? <Loader className="button-loading"/> : 'Enviar'}
        </button>
        {serverError.length > 0 && (
          <pre className="error">{serverError}</pre>
        )}
      </div>
    </form>
  );
}

export default Formulario;
