import React from 'react';
import { ImSpinner3 } from 'react-icons/im';
import './Loader.css';

function Loader(props) {
  const { className } = props;
  return <ImSpinner3 className={`spinner ${className}`} />;
}

export default Loader;
