import Formulario from './Formulario';
import { useState } from 'react';
import PaginaSucesso from './PaginaSucesso';
import Desativado from './Desativado';

function PaginaFormulario() {
  const [vitoria, setVitoria] = useState(null);

  return (
    <>
      {vitoria === true && <PaginaSucesso onNovoFormulario={() => setVitoria(false)} />}
      {vitoria === false && <Formulario onSuccess={() => setVitoria(true)} />}
      {vitoria === null && <Desativado />}
    </>
  );
}

export default PaginaFormulario;
