import { useState, useRef } from 'react';
import './PaginaSobreNos.css';
import SpeechComponent from './SpeechComponent';

import sobre1 from '../images/sobre1.jpeg'
import sobre2 from '../images/sobre2.jpeg'
import equipe from '../images/equipe.jpg'

function PaginaSobreNos() {
  const textoRef = useRef(null);

  const [textoSobre, setTextoSobre] = useState(textoRef.current?.innerText);

  return (
    <div className="centro">
      <div className='sobre-conteudo'>
        <div className="about-header">
          <h1>Sobre o projeto</h1>
          <SpeechComponent
            text={`<h1>Sobre o projeto</h1> ${textoSobre || ''}}`}
            className="speech-header"
          />
        </div>
        <div ref={textoRef} className="sobre-texto" onLoad={() => {setTextoSobre(textoRef.current?.innerText)}}>
          <p>
            O Favela Viva é uma central de atendimento comunitário para moradores de favela que foi 
            desenvolvida e idealizada em 2020 no auge da pandemia do Covid - 19. O projeto foi pensando para atender, 
            conscientizar e orientar os moradores de favela com informações qualificadas sobre como resolver diferentes 
            problemas sociais, econômicos e de saúde que eles sofrem e que foram agravados pelo contexto da pandemia, e 
            encaminhá-los para os aparelhos público mais adequados para a sua demanda, quando não for possível para o 
            voluntário resolvê-la. 
          </p>
          <img
            src={sobre1}
            alt="Realização do Atendimento Comunitário"
            className='borda-imagem-laranja'
          ></img>
          <img
            src={sobre2}
            alt="Realização do Atendimento Comunitário"
            className='borda-imagem-roxo desktop'
          ></img>
          <p>
            Ele funciona através de um sistema online pelo qual os moradores de diferentes comunidades podem se cadastrar e ter acesso 
            a atendimentos remotos nas áreas de Serviço Social e Psicologia realizados de forma gratuita por uma equipe de profissionais e 
            estudantes universitários voluntários. Os atendimentos de forma remota garantem o distanciamento social e permite que os moradores 
            o acessem de dentro de suas casas, evitando as filas e a falta de vagas em postos públicos.
          </p>
          <img
            src={sobre2}
            alt="Realização do Atendimento Comunitário"
            className='borda-imagem-roxo mobile'
          ></img>
          <p>
            A nossa equipe de extensionistas  trabalha desde a programação do sistema online à organização dos voluntários, gestão das demandas 
            vindas dos moradores e a produção de materiais informativos junto aos voluntários.
          </p> 
          <img
            src={equipe}
            alt="Equipe do favelaviva"
            className='borda-imagem-laranja'
          ></img>
        </div>
         <div className="texto" >
          <p>
            Pode-se dizer que a participação dos extensionistas do projeto é de
            total importância uma vez que proporciona um olhar mais amplo,
            possibilitando discussões multidisciplinares que levem o estudante a
            construir sua formação integral reduzindo a distância entre as
            universidades e a favela.
          </p>
         </div>
      </div>
    </div>
  );
}

export default PaginaSobreNos;
