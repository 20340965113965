import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.css';

import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './utils/ScrollToTop'

import PaginaInicial from './components/PaginaInicial';
import PaginaSobreNos from './components/PaginaSobreNos';
import PaginaFormulario from './components/PaginaFormulario';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <ScrollToTop />
        <Switch>
          <Route path="/formulario">
            <PaginaFormulario />
            <Footer />
          </Route>
          <Route path="/sobre">
            <PaginaSobreNos />
            <Footer />
          </Route>
          <Route path="/">
            <PaginaInicial />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
