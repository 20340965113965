/*
 * RecaptchaUtils.js
 */

class RecaptchaUtils {
  static getRecaptchaToken(action) {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(
          process.env.REACT_APP_RECAPTCHA_API_KEY,
          { action }
        )
        .then(token => resolve(token))
        .catch(e => reject(e))
      })
    })
  }
}

export default RecaptchaUtils
