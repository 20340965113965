import { Link } from 'react-router-dom';

import './Footer.css';

function Footer() {
  
  return (
    <footer>
      <div className='centro'>
        <div className='footer-grid'>
          <div className='info'>
            <p>Contato</p>
            <a href="mailto:favelavivarj@gmail.com" className='link' >Email</a>
            <a href="https://www.instagram.com/favelavivarj/" rel="noreferrer" target="_blank" className='link'>Instagram</a>
          </div>
          <div className='navegacao info'>
            <p>Navegação</p>
                <Link to="/" className='link'>
                  Página Inicial
                </Link>
                <Link to="/sobre" className='link'>
                  Sobre nós
                </Link>
                <Link to="/formulario" className='link'>
                  Atendimento
                </Link>
          </div>
        </div>        
      </div>
    </footer>
  );
}

export default Footer;
