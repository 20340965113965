import { Link } from 'react-router-dom';

import './Header.css';

import banner from '../../images/favelaviva-logo.png';

function Header() {
  return (
    <header>
      <div className="header-content">
        <img src={banner} className="banner" alt="banner" />
      </div>

      <div className="nav">
        <div className='nav-buttons centro'>
          <Link to="/" className="link">
            Página Inicial
          </Link>
          <Link to="/formulario" className="link">
            Cadastre-se
          </Link>
          <Link to="/sobre" className="link">
            Sobre nós
          </Link>
        </div>
      </div>
    </header>
  );
}

export default Header;
