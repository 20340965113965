import React from 'react';
import SpeechComponent from './SpeechComponent';

import './FormLabel.css'

function FormLabel(props) {
  const { title, text } = props
  return(
    <div className="form-label-container">
      <p className="form-label-text">{title}</p>
      <SpeechComponent text={text || title} className="form-label-speech-button" />
    </div>
  )
}

export default FormLabel;
