import { Link } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import SpeechComponent from './SpeechComponent';

import './PaginaInicial.css';

import instagramIcon from '../images/icons/instagram.svg';
import telegramIcon from '../images/icons/telegram.svg';

import bpLogo from '../images/logos/bp-logo.png';
import cfmLogo from '../images/logos/cfm-logo.png';
import faferjLogo from '../images/logos/faferj-logo.png';
import fiocruzLogo from '../images/logos/fiocruz.jpeg';
import fiotecLogo from '../images/logos/fiotec.png';
import govLogo from '../images/logos/gov.png';
import equipe from '../images/equipe.jpg'
import sobre1 from '../images/sobre1.jpeg'
import sobre2 from '../images/sobre2.jpeg'

function PaginaInicial() {
  const textoCentral =
    'Central de atendimento comunitário para moradores de favela';
  const textoFooter =
    'O Favela Viva é uma central de atendimento comunitário para moradores de favela, idealizado pela FAFERJ em parceria com o Coletivo Força Motriz. Ele funciona através de um sistema online pelo qual os moradores de diferentes comunidades podem se cadastrar e ter acesso a atendimentos remotos nas áreas de Serviço Social e Psicologia realizados gratuitamente por uma equipe de profissionais e estudantes universitários voluntários.';
  const textoRedes = 'Acompanhe o Favela Viva nas redes sociais!';
  return (
    <main className="secoes">

      <section className="apresentacao centro">
        <div>
          <div className="container-flex">
            <h1>Favela Viva</h1>
            <div className="speech-default-margin">
              <SpeechComponent text={textoCentral} className="speech-central" />
            </div>
          </div>
          <h4>{textoCentral}</h4>

          <div className="chamada-atendimento">
            <p>Está à procura de atendimento?</p>
            <Link to="/formulario" className="link">
              <button className="button">Quero ser atendido</button>
            </Link>
          </div>
        </div>

        <Carousel
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          className="carousel"
        >
          <div className='area-imagem borda-imagem-laranja'>
            <img
              src={sobre1}
              alt="Realização do Atendimento Comunitário"
            ></img>
          </div>
          <div className='area-imagem borda-imagem-laranja'>
            <img
              src={equipe}
              alt="Imagem de Atendimento Comunitário"
            ></img>
          </div>
          <div className='area-imagem borda-imagem-laranja'>
            <img
              src={sobre2}
              alt="Realização do Atendimento Comunitário"
            ></img>
          </div>
        </Carousel>
      </section>

      <section className="resumo-sobre">
        <div className="conteudo centro">
          <p>
            {textoFooter}
            <Link to="/sobre" className="saiba-mais">
              Saiba mais...
            </Link>
            <SpeechComponent text={textoFooter} className="align-icon" />
          </p>
          <div className="redes-sociais">
            <div className="redes-texto">
              <p>{textoRedes}</p>
              <SpeechComponent
                text={textoRedes}
                className="speech-default-margin"
              />
            </div>
            <div className="redes-sociais-icones">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/favelavivarj/"
              >
                <img className="icon" src={instagramIcon} alt="ícone telegram" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://telegram.me/favelaviva_bot"
              >
                <img className="icon" src={telegramIcon} alt="ícone instagram" />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="centro parceiros">
        <div className="apoiadores">
          <h5>Apoio</h5>
          <div className="grid-apoiadores">
            <img src={fiocruzLogo} alt="fiocruz-logo" />
            <img src={fiotecLogo} alt="fiotec-logo" />
            <img src={govLogo} alt="gov-logo" />
          </div>
        </div>

        <div className="apoiadores">
          <h5>Organização</h5>
          <div className="grid-apoiadores">
            <img src={faferjLogo} alt="faferj-logo" />
            <img src={cfmLogo} alt="cfm-logo" />
            <img src={bpLogo} alt="brigadas-logo" />
          </div>
        </div>
      </section>
    </main>
  );
}

export default PaginaInicial;
