import { MdStopCircle } from 'react-icons/md';
import { GiSpeaker } from 'react-icons/gi';

import './SpeechComponent.css';

import useSpeechSynthesis from '../hooks/speechSynthesis';

function SpeechComponent(props) {
  const { className = '', text } = props;
  const { speak, cancel, speaking } = useSpeechSynthesis();
  return (
    <>
      {speaking === false ? (
        <GiSpeaker
          className={`speech-icon ${className}`}
          onClick={() => speak({ text })}
        />
      ) : (
        <MdStopCircle
          className={`speech-icon ${className}`}
          onClick={() => cancel()}
        />
      )}
    </>
  );
}

export default SpeechComponent;
