import './PaginaSucesso.css';
function PaginaSucesso(props) {
  const { onNovoFormulario } = props;
  return (
    <div className="concluido">
      <h2>Seu cadastro foi concluido com successo!</h2>
      <button className="button" onClick={() => onNovoFormulario()}>
        Preencher outra requisição
      </button>
    </div>
  );
}

export default PaginaSucesso;
