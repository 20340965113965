import "./Desativado.css";
function Desativado(props) {
  return (
    <div className="desativado">
      <h2>Formulário temporariamente desativado</h2>
      <p>Caso necessário, entre em contato pelo nosso <a href="https://www.instagram.com/favelavivarj/" rel="noreferrer" target="_blank" className='link'>Instagram</a></p>
    </div>
  );
}

export default Desativado;
